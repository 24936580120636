import 'source-map-support/register'

import { RemixBrowser } from '@remix-run/react'
import { hydrate } from 'react-dom'

const App = () => {
    return (
        <RemixBrowser />
    )
}

hydrate(<App />, document)

if ('serviceWorker' in navigator) {
    try {
        navigator.serviceWorker
            .getRegistrations()
            .then(function (registrations) {
                for (let registration of registrations) {
                    registration.unregister()
                }
            })
    } catch (error) {
        // ignore!
    }
}

/* DISABLED --->
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker
            .register('/entry.worker.js', { type: 'module' })
            .then(() => navigator.serviceWorker.ready)
            .then(() => {
                if (navigator.serviceWorker.controller) {
                    navigator.serviceWorker.controller.postMessage({
                        type: 'SYNC_REMIX_MANIFEST',
                        manifest: window.__remixManifest
                    })
                } else {
                    navigator.serviceWorker.addEventListener('controllerchange', () => {
                        navigator.serviceWorker.controller?.postMessage({
                            type: 'SYNC_REMIX_MANIFEST',
                            manifest: window.__remixManifest,
                        })
                    })
                }
            })
            .catch((error) => {
                console.error('Service worker registration failed', error)
            })
    })
}
<--- */